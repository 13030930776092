@import "../../style/variables";
@import "../../style/mixins";

.button {
  .circle(@button_size);
  line-height: 1.428571429;
  position: relative;
  float: right;
  background-color: @klara_blue;
  color: white;
  background-size: cover;
  cursor: pointer;
  order: 2;
  align-self: flex-end;
}

.mobileButton {
  margin: @button_spacing;
}

.icon {
  width: @icon-size;
  height: @icon-size;

  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -@icon-size / 2;
  margin-top: -@icon-size / 2;
}

.open {
  .roundEdges(@button_size);
  background-color: @klara_blue;
  color: white;
  cursor: pointer;
  padding: 0 18px;
  order: 2;
  align-self: flex-end;
}
