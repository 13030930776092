@import "variables";
@import "mixins";

@font-face {
  font-family: "Cera ModMed";
  src: url("https://doctor.klara.com/Cera-ModMed-Variable.woff2")
      format("woff2"),
    url("https://doctor.klara.com/Cera-ModMed-Variable.woff") format("woff"),
    url("https://doctor.klara.com/Cera-ModMed-Variable.ttf") format("truetype");
}

@font-face {
  font-family: "Adjusted Arial Fallback";
  src: local(Arial);
  size-adjust: 96%;
  ascent-override: 107%;
  descent-override: normal;
  line-gap-override: 0%;
}

.app {
  font-family: "Cera ModMed", "Adjusted Arial Fallback", sans-serif;
  font-weight: 400;
  text-align: left;
  color: #444;
  position: fixed;
  z-index: 2147483647;
  bottom: @button_spacing;
  right: @button_spacing;
  max-height: 100%;

  display: flex;
  flex-direction: column;
}

.appFullScreen {
  font-family: "Cera ModMed", "Adjusted Arial Fallback", sans-serif;
  font-weight: 400;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.container {
  position: relative;
}

.btn-circle {
  .circle(50px);
  background-color: #76b0ea;
  line-height: 1.428571429;
  float: right;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.35);
  border: 1px solid #699dd0;
  color: white;
  background-size: cover;
}

.widgetBody {
  margin-bottom: 20px;
  position: relative;
  overflow: auto;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.35);
  border-radius: 10px;
  background-color: white;

  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
  order: 1;
}

.hide {
  display: none;
}

.widgetBodySize {
  width: 90vw;
  height: 86vh;
}

.widgetBodyFullScreen {
  width: 100%;
  height: 100%;
  margin-bottom: 0;
  position: relative;
  overflow: auto;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.35);
  border-radius: 0;
  background-color: white;
}

.klaraWidgetActiveMobile {
  overflow: hidden;
  height: 100%;
  width: 100%;
  position: fixed;
}

.widgetMobileScrolling {
  -webkit-overflow-scrolling: touch;
}

@media screen and (max-width: 600px) {
  .app {
    padding-right: 0px;
  }
}

.widget-content {
  flex-direction: column;
  display: flex;
  min-height: 100%;
}

.send-form {
  margin-right: 60px;
}
.send-box {
  width: 100%;
  background-color: white;
  padding-top: 18px;
  margin-top: auto;
  bottom: 0;
  height: 60px;
  box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.75);
}
.send-button {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAMAAAC7IEhfAAABR1BMVEUAAAB0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0rux0ruzin5TLAAAAbHRSTlMAAQIDBAUGBwgJCwwNDg8REhQWFxgcICEjJSYoKywvMTQ1Njc6PD1FRk1OT1FSVFdcXWJjZGZnaWxvcHN1eHyDhYaLkpSYmpudnqCio6atsLS1ubq8wMXHz9HV2drc3uDi5Obo6evx8/X3+/0oI6VsAAABjElEQVQYGY3Ba0MSQQAF0LuwQFhmLmVkWillpWZp0MseW5mVoL1NLQ1zhYT7/z83szvs7GMQzsEwnK9cw2CFVyQPMYi1eEKhgQGmftFXw6nGtqhUcAr7OUMl9GXdaVGz0U95h77PhxQ89HFug4HVdUoNGGUfdxm4fZ++Gkzmjhn4N3mVgQrSLm1TaV4YbTPgIKn4lj0/z2T3qNiIyyx32NPIYJOKh7gbTYZeAk/ZU0dU6Ru1JeAmQzVoBZda5zpQ6jBUQY9174Ta8UWg8JeaA2XqNyN2RwDrOyNs+Ma2GPXJBvCaER6k3AvGvLEALDCqDuFaizErEMqMqUJoMmYOwtkWYyoQ7naptScgZHcY50DKze9T2T8PaYMJNpTyB0o/8pAeMcGDVnxwxPUMpBkm1RFlXYZvvMOkKgzyf5gyizTrC9McpK3QwEaayzQPBi593ffU6jBw6buF/OIBlSoMXErPIF35SN8sDFwKm1CKy0ckx2HgktzLImRNv3sIE5dsj2IIq+QkhpF7Mg2z/1PgIgqKzg5FAAAAAElFTkSuQmCC);
  height: 20px;
  width: 20px;
  float: right;
  background-size: cover;
  margin-right: 20px;
  margin-top: 1px;
}

.message--container {
  display: flex;
  font-size: 15px;
  margin-top: 10px;
  margin-bottom: 20px;
  padding-left: 14px;
  padding-right: 14px;
}

.message--avatar {
  .circle(38px);
  background-color: @avatar_background;
  border: 1px solid #dddddd;
  margin-right: 14px;
}

.message--author {
  font-family: "Cera ModMed", "Adjusted Arial Fallback", sans-serif;
  font-size: 15px;
  color: #1d1d1b;
  font-weight: 500;
  text-align: left;
}

p {
  font-family: "Cera ModMed", "Adjusted Arial Fallback", sans-serif;
  font-size: 15px;
  color: #1d1d1b;
  font-weight: 400;
  text-align: left;
}

a {
  font-weight: 700;
  cursor: pointer;
  color: #76b0ea;
}
