.circle (@diameter) {
  width: @diameter;
  height: @diameter;
  border-radius: @diameter/2;
}

.roundEdges(@height) {
  height: @height;
  border-radius: @height/2;
}
